import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import SEO from '../../components/seo'
import PageHeader from '../../components/campaign/pageHeader'
import TeaserCampaign from '../../components/campaign/teaserCampaign'
import QuoteCampaign from '../../components/campaign/quoteCampaign'
import BannerCampaign from '../../components/campaign/bannerCampaign'
import { pushPageViewPreventDuplicates } from 'utils/gtmUtils'
import LayoutNew from 'layout/LayoutNew'

const CampaignPage = ({ data }: { data: any }) => {
  var page = data.allContentfulCampaignPage.edges[0].node
  const allPages = data.allSitePage
  let forceWhiteHeader =
    page.theme.toLowerCase() == 'blue' || page.theme.toLowerCase() == 'lightblue'

  useEffect(() => {
    document.body.classList.add('u-colorBgWhite')
    pushPageViewPreventDuplicates()
  }, [])

  return (
    <LayoutNew
      campaignHeader={page.campaignHeader}
      headerCssClass="Header--campaign"
      forceWhite={forceWhiteHeader}
    >
      <SEO description="" title="Kampanj" />
      <article>
        <PageHeader page={page} allPages={allPages} />
        {page.teasers && page.teasers.length > 0
          ? page.teasers.map((teaser: any) => {
              if (teaser.__typename === 'ContentfulCampaignQuoteBlock') {
                return <QuoteCampaign teaser={teaser} />
              } else if (teaser.__typename === 'ContentfulCampaignBanner') {
                return <BannerCampaign teaser={teaser} allPages={allPages} />
              } else {
                return <TeaserCampaign teaser={teaser} allPages={allPages} />
              }
            })
          : null}
      </article>
    </LayoutNew>
  )
}

export default CampaignPage

export const queryCampaigPage = graphql`
  query ($slug: String) {
    allSitePage {
      edges {
        node {
          path
          context {
            slug
          }
        }
      }
    }
    allContentfulCampaignPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          button {
            text
            externalLink
            link {
              slug
              contentful_id
            }
          }
          heading
          body {
            raw
          }
          expressiveHeader
          theme
          campaignHeader
          teasers {
            ... on ContentfulCampaignBanner {
              __typename
              id
              heading
              body {
                body
              }
              image {
                title
                fixed(width: 1200) {
                  src
                }
                description
              }
              listItems
              button {
                text
                externalLink
                link {
                  slug
                  contentful_id
                }
              }
              theme
            }
            ... on ContentfulCampaignBlock {
              __typename
              id
              heading
              body {
                raw
              }
              media {
                title
                fixed(width: 600) {
                  src
                }
              }
              button {
                text
                externalLink
                link {
                  slug
                  contentful_id
                }
              }
              imageLeft
            }
            ... on ContentfulCampaignQuoteBlock {
              __typename
              id
              quote
              author
              theme
              contentful_id
            }
          }
          image {
            title
            fixed(width: 1200) {
              src
            }
            description
          }
        }
      }
    }
  }
`
